import { Route, NavLink, HashRouter } from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css'
import Container from 'react-bootstrap/esm/Container';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/app.css';
import { Link, animateScroll as scroll } from "react-scroll/modules";
import Home from './pages/Home';
import Work from './pages/Work';
import Projects from './pages/Projects';
import Leadership from './pages/Leadership';
import Navbar from './components/Navbar';
import Footer from './components/Footer';

function App() {
  return (
    <>
        <HashRouter>
          <Navbar/>
          <Container style={{ marginTop: '100px', marginBottom: '50px'}}> 
            <Route exact path="/" component={Home} />
            <Route exact path="/work" component={Work} />
            <Route exact path="/projects" component={Projects} />
            <Route exact path="/leadership" component={Leadership} />
          </Container >
        </HashRouter>
        <Footer/>
    </>
  );
}

export default App;
