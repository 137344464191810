import styled from 'styled-components';
import COLORS from '../../utils/constants/colors';
import WINDOW from '../../utils/constants/window';
import { NavLink } from "react-router-dom";
import { Button, Icon, Popup } from 'semantic-ui-react';


export const NavbarFullscreenMenu = styled.div`
  background-color: ${COLORS.PRIMARY_BACKGROUND};
  opacity: 0.9;
  max-height: 70px;
  position: fixed;
  top: 0;
  z-index: 1000;
  padding: 25px 15px 25px 30px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: right;
  ${WINDOW.LARGE_MEDIA_QUERY} {
    padding: 30px 65px 30px 80px;
  };
  ${WINDOW.TABLET_MEDIA_QUERY} {
    padding-left: 24px;
    padding-right: 24px;
  }
`;

export const NavbarLinks = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-family: 'SF Pro Text';
  ${WINDOW.TABLET_MEDIA_QUERY} {
    display: flex;
  };
`

const activeClassName = "nav-item-active";
export const NavbarLink = styled(NavLink).attrs({ activeClassName })`
  font-size: 14px;
  line-height: 16px;
  color: ${COLORS.PRIMARY_FONT};
  text-decoration: none;
  margin: 0 30px;
  cursor: pointer;
  h5 {
    font-weight: 400;
  };
  &:hover {
    color: ${COLORS.ACTIVE_LINK};
    text-decoration: none;
  };
  ${WINDOW.TABLET_MEDIA_QUERY} {
    display: none;
  }
  &.${activeClassName} {
    color: ${COLORS.ACTIVE_LINK};
    text-decoration: none;
    font-size: 14px;
    line-height: 16px;
    margin: 0 30px;
    cursor: pointer;
    text-decoration: none;
    ${WINDOW.TABLET_MEDIA_QUERY} {
      display: none;
    }
  }
`;

