import React, { PureComponent } from 'react'
import '../styles/projects.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const techDict = {
    "AWS": "btn btn-warning",
    "Chrome": "btn btn-info",
    "Python": "btn btn-success",
    "JavaScript": "btn btn-primary",
    "Jupyter Notebook": "btn btn-success",
}

export default class ProjectsGridElement extends PureComponent {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div class="col-lg">
                <div class="img__wrap">
                    <img class="img__img" src={this.props.img} />
                    <a class="link__layer" href={this.props.link} target="_blank">
                        <div class="img__description_layer">
                            <div class="img__description">
                                <div class="button_group">
                                    {this.props.tech.map(element => {
                                        return (<button type="button" class={techDict[element]}>{element}</button>)
                                    })}
                                </div>
                                <h2>
                                    <a class="project__link" href={this.props.link} target="_blank">
                                        {this.props.title}
                                    </a>
                                </h2>
                                <p>{this.props.desc}</p>
                                <div class="icon_group">
                                    {this.props.icons.map(iconType => {
                                        return (<FontAwesomeIcon icon={iconType} style={{ margin: '0 15px 0 0' }} />)
                                    })}
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        )
    }
}
