import * as S from './Navbar.styles';

const Navbar = () => {
  const hideLinks = false;
  const renderLinks = () => {
    const itemSet = [
      { title: "Projects", link: "/projects" },
      { title: "Work", link: "/work" },
      { title: "Leadership", link: "/leadership" },
      // { title: "BLOGS", link: "/blogs" },
    ]
    return (
      <>
        {itemSet.map(link => {
          return  (
          <S.NavbarLink exact to={link.link}>
            <h5>{link.title}</h5>
          </S.NavbarLink>
          )       
        })}
      </>
    );
  }
  return (
    <>
      <S.NavbarFullscreenMenu>
        <S.NavbarLink exact to='/'>
          <h5>Maanav</h5>
        </S.NavbarLink>       
        {!hideLinks &&
          <S.NavbarLinks>
            {renderLinks()}
          </S.NavbarLinks>
        }
      </S.NavbarFullscreenMenu>
    </>
  );
};
export default Navbar;