import React, { useState } from 'react';
import * as S from './Footer.styles';
import images from '../../images';

const Footer = () => {
  return (
    <S.Container>
      <S.Horizontal>
        <S.Column>
          <S.Title>Work Experience</S.Title>
          <S.Link href='https://www.citadel.com' target='_blank'>Citadel</S.Link>
          <S.Link href='https://www.coinbase.com' target='_blank'>Coinbase</S.Link>
          <S.Link href='https://corp.roblox.com' target='_blank'>Roblox</S.Link>
          <S.Link href='https://www.halo.science' target='_blank'>Halo</S.Link>
          <S.Link href='https://www.microsoft.com' target='_blank'>Microsoft</S.Link>
          <S.Link href='https://www2.deloitte.com/ca/en.html' target='_blank'>Deloitte</S.Link>
          <S.Link href='https://www.ibm.com/ca-en' target='_blank'>IBM</S.Link>
        </S.Column>
        <S.Column>
          <S.Title>Projects</S.Title>
          <S.Link href='https://github.com/Maanav-G/Netflix-Wrapped' target='_blank'>Netflix Wrapped</S.Link>
          <S.Link href='http://walletapp.maanavgarg.com' target='_blank'>Wallet</S.Link>
          <S.Link href='https://github.com/Maanav-G/nba-rookie-statistics-analysis' target='_blank'>NBA Rookie Statistics</S.Link>
          <S.Link href='https://github.com/Maanav-G/questrade-api-wrapper' target='_blank'>Questrade API Wrapper</S.Link>
          <S.Link href='https://github.com/Maanav-G/recommendation-engine' target='_blank'>Recommendation Engine</S.Link>
        </S.Column>
        <S.Column>
          <S.Title>Connect w/ Me</S.Title>
          <S.Link href='https://www.linkedin.com/in/maanavgarg/' target='_blank'>LinkedIn</S.Link>
          <S.Link href='https://github.com/Maanav-G' target='_blank'>GitHub</S.Link>
          <S.Link href='mailto:maanavgarg@icloud.com' target='_blank'>Email</S.Link>
          <S.Link href='https://medium.com/@maanavgarg' target='_blank'>Medium</S.Link>
        </S.Column>
      </S.Horizontal>
      <S.Bottom>
        <S.Terms>
          <div>
            Developed and designed by Maanav Garg, © 2023
          </div>
        </S.Terms>
      </S.Bottom>
    </S.Container>
  );
}

export default Footer;