import React, { Component } from 'react'
import styled from 'styled-components';

const TitleContainer = styled.div`
    text-align: center;
    margin-bottom: 40px;
    h3 {
        font-weight: 100;   
    };
`

export default class Title extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <TitleContainer>
                <h3>{this.props.mainTitle != "" && this.props.mainTitle}</h3>
                <p class="lead">{this.props.subTitle != "" && this.props.subTitle}</p>

                {this.props.note != "" && 
                    <figcaption class="blockquote-footer">
                        {this.props.note}
                    </figcaption>
                }
            </TitleContainer>
        )
    }
}
