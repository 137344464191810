import React, { PureComponent } from 'react';
import '../styles/projects.css';
import styled from 'styled-components';
import images from '../images';
import Title from '../components/Title';
import { faChrome, faAws } from '@fortawesome/free-brands-svg-icons';
import { faTools, faChartBar, faDatabase, faGlobe } from '@fortawesome/free-solid-svg-icons';
import { Image, Card, List, Button, Icon, Label } from 'semantic-ui-react';

const work_list = [
    {
        company: "Ripple Ventures",
        subteam: "",
        image: "",
        role: "Ripple Fellow",
        date: "May 2021 - Present",
        location: "Toronto, ON",
        tech: [],
        desc: [
        ],
    },
    {
        company: "Kleiner Perkins",
        subteam: "",
        image: "",
        role: "KP Fellow",
        date: "February 2021 - Present",
        location: "Toronto, ON",
        tech: [],
        desc: [
        ],
    },
    {
        company: "Bessemer Venture Partners",
        subteam: "",
        image: "",
        role: "BVP Fellow",
        date: "August 2020 - Present",
        location: "Toronto, ON",
        tech: [],
        desc: [
            "Scouted as 1 of the 20 Bessemer Fellows for 2021 from all across North America - bvp.com/bessemer-fellows",
        ],
    },
    {
        company: "Deloitte",
        subteam: "",
        image: "deloitte",
        role: "DNLC Delegate",
        date: "June 2019",
        location: "Toronto, ON",
        tech: [],
        desc: [
            "Scouted as 1 of the 100 students from all across Canada to attend the Deloitte National Leadership Conference, for Consulting.",
            "Placed 1st for the conference-wide Deloitte case competition.",
            "Received an internship offer in Consulting in conjunction with the conference."
        ],
    },
    {
        company: "DeltaHacks",
        subteam: "",
        image: "",
        role: "Vice President, Sponsorship and Finance",
        date: "January 2019 - January 2020",
        location: "Hamilton, ON",
        tech: [],
        desc: [
            "Directed a cross-functional executive team to obtain funding from 22 corporate sponsors, yielding a year-over-year 31% increase in funds, a 25% surge in company interest, 12% improvement in sponsor retention and a 10% negotiated increase in initially proposed funding.",
            "Formalized a comprehensive design of the sponsorship packaging strategy which amplified our opportunities offered."
        ],
    },
    {
        company: "EDGE",
        subteam: "",
        image: "",
        role: "Technology Lead",
        date: "January 2020 - August 2020",
        location: "Toronto, ON",
        tech: [],
        desc: [
            "Directing all technical application-related platforms for EDGE, including but not limited to, the attendee sign-in portal, organization’s website and back-end database."
        ],
    },
    {
        company: "Microsoft",
        subteam: "",
        image: "microsoft",
        role: "Student Partner",
        date: "September 2019 - December 2019",
        location: "Toronto, ON",
        tech: [],
        desc: [
            "Organized 10 Microsoft focussed ‘Career and Development’ events, including workshops, case competitions and professional keynotes while managing the Events, Finance and Logistics portfolios.",
            "Hosted a range of technical workshops on Microsoft technologies (Azure, AI, Machine Learning, etc.) that entailed me to design and teach the content.",
            "Partnered up with campus organizations, to amplify the sheer magnitude, volume, and outreach of the events."
        ],
    },
    {
        company: "Mathematical Investment Research Council",
        subteam: "",
        image: "",
        role: "Research Analyst",
        date: "September 2019 - December 2019",
        location: "Hamilton, ON",
        tech: [],
        desc: [
            "Implemented and developed quantitive trading strategies using Python by designing mock-ups of mathematical models using machine learning techniques, while focusing on time series strategies, future/ETF arbitrage, and cross-sectional strategies.",
            "Worked on creating valuation to analyze current market data, provide financial analysis and support industry research."
        ],
    },
    {
        company: "IBM",
        subteam: "",
        image: "ibm",
        role: "Future Blue Lead",
        date: "January 2019 - August 2019",
        location: "Toronto, ON",
        tech: [],
        desc: [
            "Managed a 16-member team, by overseeing the Events, Relations, Marketing, and Logistics portfolios.",
            "Organized 44 ‘Career and Development’ events throughout the 8-months, including workshops, case competitions and professional keynotes, catered towards IBM interns within consulting, finance, marketing, sales and technology.",
            "Earned a 50% raise in the allocated budget from human resources, by demonstrating an increase of intern turnout by 47% to 63% at each event."
        ],
    },
    {
        company: "Waterloop",
        subteam: "",
        image: "",
        role: "Software Engineer",
        date: "May 2018 - August 2018",
        location: "Waterloo, ON",
        tech: [],
        desc: [
            "In-charge of the web development and management of https://teamwaterloop.ca using HTML, CSS, Javascript and React.",
            "Collaborated with our graphics executive team to effectively create and design components of the website.",
        ],
    },
];

const ButtonGroup = styled.div`
    text-align: center;
    margin-bottom: 40px;

`

export default class Leadership extends PureComponent {
    render() {
        return (
            <>
                <Title
                    mainTitle="Leadership Experience &#128173;"
                    subTitle="Here you can see all of my extracurricular and leadership roles"
                    note="Each section outlines the organization, my role, time spent and a brief description of what I did"
                />

                <ButtonGroup>
                    <Button.Group attached='top'>
                    <Button  
                        color='black'
                        onClick={() => {window.open('https://www.linkedin.com/in/maanavgarg/','_blank')}}
                    >
                    <Icon name='linkedin' /> LinkedIn
                    </Button>
                    <Button color='black'>
                    <Icon name='file' /> Resume
                    </Button>
                    </Button.Group>
                </ButtonGroup>

                    
                <Card.Group>
                    {work_list.map(work => {
                        console.log(work)
                        return (
                            <Card fluid key={work.company}>
                                <Card.Content>
                                    {work.image != "" &&
                                        <Image
                                        floated='right'
                                        size='small'
                                        src={images[work.image]}
                                        />
                                    }
                                    <Card.Header>{work.company}</Card.Header>
                                    <Card.Meta>
                                        <strong>{work.role}</strong> <br/>
                                        {work.date} 
                                    </Card.Meta>
                                    <br/>
                                    <Card.Description>
                                        <List as='ol'>
                                            {work.desc.map(item => {
                                                return (
                                                <List.Item as='li' value='-'>
                                                    {item}
                                                </List.Item>
                                                )
                                            })}
                                        </List>
                                    </Card.Description> 
                                    {work.tech.length > 0 &&
                                    <>
                                        <br/>
                                        <Card.Description>
                                            <Button.Group basic>
                                                {work.tech.map(t =>{
                                                    return <Button>{t}</Button>
                                                })}
                                            </Button.Group>
                                        </Card.Description>
                                    </>
                                    }
                                </Card.Content>
                            </Card>
                        )
                    })}
                </Card.Group>
            </>
        )
    }}
