import React, { PureComponent } from 'react';
import '../styles/projects.css';
import styled from 'styled-components';
import images from '../images';
import ProjectsGridElement from '../components/ProjectsGridElement';
import Title from '../components/Title';
import { faChrome, faAws } from '@fortawesome/free-brands-svg-icons';
import { faTools, faChartBar, faDatabase, faGlobe } from '@fortawesome/free-solid-svg-icons';
import { Image, Card, List, Button, Icon, Label } from 'semantic-ui-react';

const projects = [
    [
        {
            title: "NETFLIX WRAPPED",
            desc: "Your 2020 Netflix year in review, in the form of a Google Extension that fetches your viewing history and builds a concise dashboard with all your statistics from the past year.",
            tech: ["Chrome", "Python", "JavaScript", "AWS"],
            icons: [faChrome, faTools],
            link: "https://github.com/Maanav-G/Netflix-Wrapped",
            img: images.netflixWrapped
        },
        {
            title: "WALLET",
            desc: "Wallet is a personal financial management application that provides the user with an aggregated dashboard with all their connected account(s), which allows them to monitor and budget their personal finances.",
            tech: ["Python", "JavaScript", "AWS"],
            icons: [faGlobe, faAws],
            link: "http://walletapp.maanavgarg.com",
            img: images.wallet
        },
    ],
    [
        {
            title: "NBA ROOKIE STATISTICS",
            desc: "A mathematical machine learning model designed to predict the regular season averaging statistics for incoming rookies (2019-2020).",
            tech: ["Jupyter Notebook", "Python"],
            icons: [faDatabase, faChartBar],
            link: "https://github.com/Maanav-G/nba-rookie-statistics-analysis",
            img: images.nba
        },
        {
            title: "QUESTRADE API WRAPPER",
            desc: "This is a custom Python wrapper for the Questrade API that I built to analyze and retrieve key information from my personal Questrade Trading Account.",
            tech: ["Python"],
            icons: [faTools],
            link: "https://github.com/Maanav-G/questrade-api-wrapper",
            img: images.questrade
        },
        {
            title: "RECOMMENDATION ENGINE",
            desc: "A machine learning recommendation engine, that takes a movie as input and returns 10 similar ones, using cosine similarity.",
            tech: ["Python"],
            icons: [faChartBar],
            link: "https://github.com/Maanav-G/recommendation-engine",
            img: images.movies
        },
    ]
]

const ButtonGroup = styled.div`
    text-align: center;
    margin-bottom: 40px;
`

export default class Projects extends PureComponent {
    render() {
        return (
            <>
                <Title 
                    mainTitle="Personal Projects &#128230;"
                    subTitle="Here you can see a bunch of projects I've worked on in the past couple of years."
                    note="Hover over the projects below to view more details, some even have demos"
                />

                <ButtonGroup>
                    <Button.Group attached='top'>
                    <Button  
                        color='black'
                        onClick={() => {window.open('https://github.com/Maanav-G','_blank')}}
                    >
                    <Icon name='github' /> GitHub
                    </Button>
                    </Button.Group>
                </ButtonGroup>


                {projects.map(row => {
                    return (
                        <div class="row">
                            {row.map(item => {
                                return (
                                    <ProjectsGridElement
                                        title={item.title}
                                        tech={item.tech}
                                        link={item.link}
                                        desc={item.desc}
                                        img={item.img}
                                        icons={item.icons}
                                    />
                                )
                            })}
                        </div>
                    )
                })}
            </>
        )
    }
}
