import styled from 'styled-components';
import { FaLinkedin, FaGithub, FaMedium, FaEnvelope, FaCalendarAlt } from 'react-icons/fa';
import WINDOW from '../utils/constants/window'

const IconGroup = styled.div`
    margin-top: 75px;
    a {
        text-decoration: none;
        color: black;
        * {
            margin-right: 25px;
            margin-left: 25px;
            font-size: 30px;
            transition: 0.05s ease-in-out;
            opacity: 0.8;
            :hover {
                opacity: 1;
            }

            ${WINDOW.TABLET_MEDIA_QUERY} {
                font-size: 35px;
                margin-right: 10px;
                margin-left: 10px;
            };
            ${WINDOW.MOBILE_MEDIA_QUERY} {
                font-size: 35px;
                margin-right: 10px;
                margin-left: 10px;
            };
        }
    }
`

const Socials = () => {
    return (
        <IconGroup>
            <a href='https://www.linkedin.com/in/maanavgarg/' target='_blank'>
                <FaLinkedin />
            </a>
            <a href='https://github.com/Maanav-G' target='_blank'>
                <FaGithub />
            </a>
            <a href='https://medium.com/@maanavgarg' target='_blank'>
                <FaMedium />
            </a>
            <a href='mailto:maanavgarg@icloud.com' target='_blank'>
                <FaEnvelope />
            </a>
            <a href='https://calendly.com/maanavgarg/30min' target='_blank'>
                <FaCalendarAlt />
            </a>
        </IconGroup>
    );
}

export default Socials;