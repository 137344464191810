import netflixWrapped from './netflixWrapped.png';
import movies from './movies.png';
import nba from './nba.png';
import questrade from './questrade.png';
import wallet from './wallet.png';
import github from './github.svg';
import logo from './logo.svg';
import roblox from './roblox.png';
import citadel from './citadel.jpg';
import deloitte from './deloitte.png';
import ibm from './ibm.png';
import microsoft from './microsoft.png';
import mac from './mac.png';
import halo from './halo.svg';

const images = {
    github,
    netflixWrapped,
    movies,
    nba,
    questrade,
    wallet,
    logo,
    roblox,
    citadel,
    deloitte,
    ibm,
    microsoft,
    mac,
    halo,
}
export default images;

