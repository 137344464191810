import React, { PureComponent } from 'react';
import '../styles/projects.css';
import COLORS from '../utils/constants/colors';
import styled from 'styled-components';
import images from '../images';
import Title from '../components/Title';
import { faChrome, faAws } from '@fortawesome/free-brands-svg-icons';
import { faTools, faChartBar, faDatabase, faGlobe } from '@fortawesome/free-solid-svg-icons';
import { Image, Card, List, Button, Icon, Label } from 'semantic-ui-react';

const work_list = [
    {
        company: "Citadel",
        subteam: "",
        link: "https://www.citadel.com/",
        image: "citadel",
        role: "Software Engineer",
        date: "August 2022 - Present",
        location: "New York, NY",
        tech: ["Python", "SQL", ],
        desc: [
            "Currently on Garden Leave."
        ],
    },

    {
        company: "Coinbase",
        subteam: "",
        link: "https://www.coinbase.com/home",
        image: "coinbase",
        role: "Software Engineer Intern",
        date: "January 2022 - April 2022",
        location: "New York, NY",
        tech: ["Go", "Ruby on Rails"],
        desc: [
            "Designed and implemented an internal framework using Go, that automates the user eligibility assessment for Coinbase’s BTC Borrow initiative, which significantly reduced the need for human labour and improved approval response time by 17%, while also providing internal teams with valuable user data.",
            "Optimized an internal framework that handled routing internal endpoints using Ruby on Rails, by introducing a caching layer to improve performance.",
            "Supported Coinbase’s company wide migration effort from Ruby on Rails to Go, by redesigning and redeveloping multiple APIs and internal endpoints."
        ],
    },
    {
        company: "Citadel",
        subteam: "",
        link: "https://www.citadel.com/",
        image: "citadel",
        role: "Software Engineer Intern",
        date: "September 2021 - December 2021",
        location: "New York, NY",
        tech: ["Python", "SQL", ],
        desc: [
            "Created a new framework using Python upon an internal modelling system for specifying N-dimensional scenarios for an arbitrary model, which enabled a quicker execution rate, higher level of abstraction, more robust definitions and an overall decrease in technical debt.",
            "Developed several prototypes on an iterative basis for various internal users, and drove presentations to scope out early adopters and gather feedback.",
            "Enhanced an existing API and system based on redefined business and user needs, while focussing on decreasing asymptotic complexity and implementing 8 new features, resulting in a system used by various internal desks on a day-to-day basis."
        ],
    },
    {
        company: "Roblox",
        subteam: "",
        link: "https://corp.roblox.com/",
        image: "roblox",
        role: "Software Engineer Intern",
        date: "June 2021 - August 2021",
        location: "San Mateo, CA",
        tech: [],
        desc: [
            "Spearheaded an internal vulnerability detection dashboard that allowed the InfoSec team to attain quicker visibility on breaches in the Roblox ecosystem.",
            "Developed high-performance mathematical models using Python that automatically identified true severity level, true ownership, and a true ranking for each vulnerability based on a combination of trained datasets and past patterns, which lowered technical debt, manual attribution and increased actionability.",
            "Leveraged SQL and Python to design an ETL pipeline that dynamically combined and engineered information from various vulnerability dataset streams."
        ],
    },
    {
        company: "Halo Science",
        subteam: "",
        link: "https://www.halo.science/",
        image: "halo",
        role: "Software Engineer Intern",
        date: "March 2021 - Present",
        location: "Chicago, IL",
        tech: ["Ruby on Rails", "ReactJS", "JavaScript"],
        desc: [
            "Developing and growing Halo Science’s main web application built on Ruby on Rails serving a React front-end.",
            "Spearheading a variety of growth initiatives including designing new features, implementing strategies and formulating the future of the product."
        ],
    },
    {
        company: "Microsoft",
        subteam: "Rich Navigation, DevDiv",
        link: "https://www.microsoft.com/en-ca?spl=2",
        image: "microsoft",
        role: "Software Engineer Intern",
        date: "May 2020 - August 2020",
        location: "Seattle, WA",
        tech: ["C#", "ASP.NET", "JavaScript", "ReactJS", "SQL", "Azure"],
        desc: [
            "Developed an interactive telemetry based dashboard using C# with ASP.NET and JavaScript for Visual Studio’s extension ecosystem.",
            "Leveraged Kusto and SQL for extracting and engineering data, by translating extension telemetry into digestible information for the dashboard by processing the data into an Azure SQL Database and using an Azure WebJob that runs periodically (1 hour) to append any new data.",
            "Designed and implemented over 15 different components for the dashboard’s UI using ReactJS and TypeScript.",
            "Implemented security protocols to protect the dashboard’s server and users from malicious activities using Azure KeyVault and Application Gateway."
        ],
    },
    {
        company: "Deloitte Consulting",
        subteam: "Systems Engineering",
        link: "https://www2.deloitte.com/ca/en.html",
        image: "deloitte",
        role: "Consulting Analyst - Intern",
        date: "January 2020 - April 2020",
        location: "Toronto, ON",
        tech: ["Project Management", "Functional Delivery", "Confluence" , "JIRA", "Excel/VBA"],
        desc: [
            "Consulted client-side for a large aviation conglomerate on delivering a ‘Loyalty’ program from end-to-end. ",
            "Acted as the primary functional analyst for our feature team, where I was the liaison between the client and vendors for all technical and business processes.",
            "Solely delivered 4 major functional features that entailed managing the vendors, ensuring deadlines were met, and requirements were signed-off on.",
            "Facilitated PI planning by adhering to the project roadmap and capturing business requirements to appropriately allocate feature delivery sprint-by-sprint.",
            "Managed and ensured accuracy on the Confluence and JIRA platforms by assuring all on-going changes were appropriately reflected."
        ],
    },
    {
        company: "IBM",
        subteam: "Securities Industry Services",
        link: "https://www.ibm.com/ca-en",
        image: "ibm",
        role: "Software Engineer Intern",
        date: "May 2018 - August 2019 (16-months)",
        location: "Toronto, ON",
        tech: ["Java", "JavaScript", "Python", "IBM Cloudant", "IBM Db2"],
        desc: [
            "Supported client-side development for TD, National Bank, HSBC, and Desjardins by working on 8 distinct projects, primarily using Java and Python.",
            "Designed and developed a fully functioning REST API using Python that stored the required order data based on the transaction ID in an IBM Cloudant Database by connecting to the Thompson Reuter server and cross-referencing with internal data and requirements.",
            "Increased TD’s orders-processed per second by ~100% (600,000), by implementing Java to redesign their stock order engine, while also overseeing the QA effort and handling required post-delivery maintenance.",
            "Streamlined an internal unit-testing process by 20% (4hr) by creating a Python script that automates test-case construction, using an internal SQL Database."
        ],
    },
    {
        company: "McMaster University",
        subteam: "Faculty of Computing & Software",
        link: "https://www.mcmaster.ca/",
        image: "mac",
        role: "Research Assistant",
        date: "January 2019 - May 2019",
        location: "Hamilton, ON",
        tech: ["Elm", "Haskell"],
        desc: [
            "Implemented Elm and Haskell to support Dr. Christopher Anand’s current research on computational algebraic thinking.",
            "Developed 14 quantitative models of gradient waveforms based on Dr. Anand’s recent research on ‘MRI’ by analyzing gathered data."
        ],
    },
    {
        company: "Mixem",
        subteam: "User Interface",
        link: "https://mixemapp.com/",
        image: "",
        role: "Software Engineer Intern",
        date: "January 2018 - April 2018",
        location: "Toronto, ON",
        tech: ["Swift", "JavaScript", "ReactJS"],
        desc: [
            "Designed and implemented the front-end portion of Mixem’s mobile application primarily using JavaScript and Swift with React Native.",
        ],
    },
];

const ButtonGroup = styled.div`
    text-align: center;
    margin-bottom: 40px;
`

const HeaderLink = styled.a`

    text-decoration: none;
    color: ${COLORS.ACTIVE_LINK};
    :hover { 
        text-decoration: none;
        color: ${COLORS.HOVER_LINK}
    }
`

export default class Work extends PureComponent {
    render() {
        return (
            <>
                <Title
                    mainTitle="Work Experience &#128084;"
                    subTitle="Here you can see all the places I've worked at so far, as well as what I did there."
                    note="Each section outlines the company, the role, my tenure and a brief description of what my job entailed"
                />

                <ButtonGroup>
                    <Button.Group attached='top'>
                    <Button  
                        color='black'
                        onClick={() => {window.open('https://www.linkedin.com/in/maanavgarg/','_blank')}}
                    >
                    <Icon name='linkedin' /> LinkedIn
                    </Button>
                    </Button.Group>
                </ButtonGroup>

                    
                <Card.Group>
                    {work_list.map(work => {
                        console.log(work)
                        return (
                            <Card fluid key={work.company}>
                                <Card.Content>
                                    {work.image != "" &&
                                        <Image
                                        floated='right'
                                        size='small'
                                        src={images[work.image]}
                                        />
                                    }
                                    <Card.Header>
                                        <HeaderLink href={work.link} target="_blank">
                                            {work.company}
                                        </HeaderLink>
                                    </Card.Header>
                                    <Card.Meta>
                                        <strong>{work.role}</strong> <br/>
                                        {work.date} 
                                    </Card.Meta>
                                    <br/>
                                    <Card.Description>
                                        <List as='ol'>
                                            {work.desc.map(item => {
                                                return (
                                                <List.Item as='li' value='-'>
                                                    {item}
                                                </List.Item>
                                                )
                                            })}
                                        </List>
                                    </Card.Description> 
                                    {work.tech.length > 0 &&
                                    <>
                                        <br/>
                                        <Card.Description>
                                            <Button.Group basic>
                                                {work.tech.map(t =>{
                                                    return <Button>{t}</Button>
                                                })}
                                            </Button.Group>
                                        </Card.Description>
                                    </>
                                    }
                                </Card.Content>
                            </Card>
                        )
                    })}
                </Card.Group>
            </>
        )
    }}
