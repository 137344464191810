import React, { PureComponent } from 'react'
import Title from '../components/Title'
import styled from 'styled-components';
import { Grid, Image } from 'semantic-ui-react';
import images from '../images';
import Socials from '../components/Socials'
import COLORS from '../utils/constants/colors';
const DESKTOP = 1280;
const TABLET_LANDSCAPE = 991;
const TABLET = 767;
const MOBILE = 479;
const LARGE_MEDIA_QUERY = `@media screen and (min-width: ${DESKTOP + 1}px)`;
const DESKTOP_MEDIA_QUERY = `@media screen and (max-width: ${DESKTOP}px)`;
const TABLET_LANDSCAPE_MEDIA_QUERY = `@media screen and (max-width: ${TABLET_LANDSCAPE}px)`;
const TABLET_MEDIA_QUERY = `@media screen and (max-width: ${TABLET}px)`;
const MOBILE_MEDIA_QUERY = `@media screen and (max-width: ${MOBILE}px)`;


const HomeContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`

const HomePage = styled.div`
    height: 100vh;
`

const HeaderContainer = styled.div`
    text-align: center;
    margin-bottom: 25px;
    h4 {
        font-weight: 100;
    }
`

const ImgLink = styled.a`
    text-decoration: none;
    color: black;
`

const MainLogo = styled.img`
    width: 15em;
    margin: 4vh 0 4vh 0;
    transition: 0.3s ease-in-out;
    opacity: 0.8;
    :hover {
        opacity: 1;
        transform: scale(1.03);
    }
`

const Paragraph = styled.p`
    font-size: 1.1em;
    text-align: left;
    width: 100%;
    a {
        text-decoration: none;
        color: ${COLORS.ACTIVE_LINK};
        :hover { 
            text-decoration: none;
            color: ${COLORS.HOVER_LINK}
        }
    }
    ${LARGE_MEDIA_QUERY} {
        width: 100%
    };
    ${TABLET_MEDIA_QUERY} {
        width: 100%
    };
 `

export default class Home extends PureComponent {
    render() {
        return (
            <HomePage>
                <HomeContainer>
                <HeaderContainer>
                    <ImgLink href="/">
                    <MainLogo src={images.logo}/>
                    </ImgLink>
                    <Paragraph>
                        Hey! My name is Maanav Garg, and I’m currently a Quantitative Developer at <a href="https://www.citadel.com" target="_blank"> Citadel</a>, while also pursuing a MS in Computer Science at <a href="https://www.gatech.edu" target="_blank"> Georgia Tech</a>.
                        <br /> <br />
                        Prior to this, I graduated from <a href="https://www.eng.mcmaster.ca/cas/programs/degree-options/basc/computer-science" target="_blank">McMaster University</a> in 2022, with a BASc. Honours Computer Science, Summa Cum Laude, GPA: 3.9/4.0. During University, I gained 3.5 years of internship work experience as well as 1 year of research and teaching assistant experience. Feel free to check out my resume, or the Work section on my site. 
                        <br /> <br />
                        I love talking about Crypto, the NBA and about new coffee or matcha spots in the city, I'm also a sucker for a good book or TV Show recommendation - So feel free to hit me up via email or set-up some time in my schedule by clicking the calendar below!
                    </Paragraph>
                    <Socials/>
                </HeaderContainer>
                </HomeContainer>
            </HomePage>
        )
    }
}
