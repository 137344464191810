import styled from 'styled-components';
import COLORS from '../../utils/constants/colors';
import WINDOW from '../../utils/constants/window';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${COLORS.PRIMARY_BACKGROUND};
  padding: 55px 0px 55px 95px;
  color: ${COLORS.PRIMARY_FONT};
  font-size: 14px;
  box-sizing: content-box;
  line-height: 1.2;
  font-weight: lighter;
  letter-spacing: 0.06rem;

  a {
    color: ${COLORS.PRIMARY_FONT};
    :hover {
      text-decoration: none;
    }
  }

  ${WINDOW.TABLET_MEDIA_QUERY} {
    font-size: 12px;
    padding: 40px 0 40px 8vw;
  }

  ${WINDOW.MOBILE_MEDIA_QUERY} {
    overflow: hidden;
  }
`;

export const Horizontal = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 13vw;

  ${WINDOW.TABLET_MEDIA_QUERY} {
    justify-content: start;
    padding-right: 0;
    
    div:last-child {
      margin-right: 0;
    }
  }

  ${WINDOW.MOBILE_MEDIA_QUERY} {
    flex-direction: column;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;

  ${WINDOW.TABLET_MEDIA_QUERY} {
    margin-right: 7vw; 
  }  
`;

export const Title = styled.div`
  font-weight: bold;
  font-family: 'SF Pro Text Medium', -apple-system, sans-serif;;
  letter-spacing: 0.02rem;
  margin-bottom: 20px;

  ${WINDOW.TABLET_MEDIA_QUERY} {
    margin-bottom: 15px;
  }
`;

export const Link = styled.a`
  margin-bottom: 15px;

  ${WINDOW.TABLET_MEDIA_QUERY} {
    margin-bottom: 11px;
  }
`;

export const SocialLogo = styled.img`
  height: 20px;
  margin-right: 20px;
`;
 
export const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 25px;
  ${WINDOW.TABLET_MEDIA_QUERY} {
    margin-top: 15px;
  }
`;


export const Terms = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 110px;
  ${WINDOW.TABLET_MEDIA_QUERY} {
    height: 55px;
    flex-direction: column;
    margin: 10px 0 0;
  }
`;